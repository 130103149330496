<template>
  <section>
    <div class="content-header">
      <h2>Linking students to viable, creative and innovative occupations </h2>
    </div>
    <div class="content-wrapper">
      <p>Studies have shown that Canadian post-secondary institutions have not kept pace with the job market. What’s more, students are often not getting the information they need to plan their education.</p>
      <p>In the helpful presentation, Career Mapping: Expanding Innovative Pathways for Employment Security, found on the  <a href="https://cannexus.ceric.ca/cannexus20-handouts-powerpoints/?limit=5&q=career&catid=23" target="_blank">Cannexus20 Handouts & PowerPoints site</a>, Dr. Linda Pardy of the University of the Fraser Valley highlights the importance of identifying non-linear pathways that link post-secondary programs to a variety of careers.</p>
      <p>You’ll find out more about the pivotal role that faculty, instructors and educators can play in motivating students.  </p>
      <!-- <img src="@/assets/img/_content/career-discuss-3.png" class="img-fluid img-thumb" alt="" srcset=""> -->
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '03',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
